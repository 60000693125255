exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-pl-js": () => import("./../../../src/pages/about.pl.js" /* webpackChunkName: "component---src-pages-about-pl-js" */),
  "component---src-pages-conditions-js": () => import("./../../../src/pages/conditions.js" /* webpackChunkName: "component---src-pages-conditions-js" */),
  "component---src-pages-conditions-pl-js": () => import("./../../../src/pages/conditions.pl.js" /* webpackChunkName: "component---src-pages-conditions-pl-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-pl-js": () => import("./../../../src/pages/contact.pl.js" /* webpackChunkName: "component---src-pages-contact-pl-js" */),
  "component---src-pages-copyright-js": () => import("./../../../src/pages/copyright.js" /* webpackChunkName: "component---src-pages-copyright-js" */),
  "component---src-pages-copyright-pl-js": () => import("./../../../src/pages/copyright.pl.js" /* webpackChunkName: "component---src-pages-copyright-pl-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-pl-js": () => import("./../../../src/pages/index.pl.js" /* webpackChunkName: "component---src-pages-index-pl-js" */),
  "component---src-pages-interpreting-js": () => import("./../../../src/pages/interpreting.js" /* webpackChunkName: "component---src-pages-interpreting-js" */),
  "component---src-pages-interpreting-pl-js": () => import("./../../../src/pages/interpreting.pl.js" /* webpackChunkName: "component---src-pages-interpreting-pl-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-privacy-pl-js": () => import("./../../../src/pages/privacy.pl.js" /* webpackChunkName: "component---src-pages-privacy-pl-js" */),
  "component---src-pages-references-js": () => import("./../../../src/pages/references.js" /* webpackChunkName: "component---src-pages-references-js" */),
  "component---src-pages-references-pl-js": () => import("./../../../src/pages/references.pl.js" /* webpackChunkName: "component---src-pages-references-pl-js" */),
  "component---src-pages-translation-js": () => import("./../../../src/pages/translation.js" /* webpackChunkName: "component---src-pages-translation-js" */),
  "component---src-pages-translation-pl-js": () => import("./../../../src/pages/translation.pl.js" /* webpackChunkName: "component---src-pages-translation-pl-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

